import * as ko from 'knockout';
import {components, Observable, Subscribable} from 'knockout';
import ensureObservable from "../util/ensure-observable"
import ComponentInfo = components.ComponentInfo;
import Config = components.Config;

interface TextInputViewModelParams {
    value: any | Subscribable;
    name: string | Subscribable<string>;
    cssClass: string | Subscribable<string>;
    cssPrefix: string | Subscribable<string>;
    i18nPrefix: string | Subscribable<string>;
    inputType: string | Subscribable<string>;
    onFocus: string | Subscribable<string>;
    showLabel: boolean | Subscribable<boolean>;
}

class TextInputViewModel {
    public value: Subscribable<string>;
    public name: Subscribable<string>;
    public cssClass: Subscribable<string>;
    public cssPrefix: Subscribable<string>;
    public i18nPrefix: Subscribable<string>;
    public inputType: Subscribable<string>;
    public onFocus: Subscribable<string>;
    public showLabel: Subscribable<boolean>;

    constructor(private params: TextInputViewModelParams) {
        this.name = ensureObservable(params.name);
        this.value = ensureObservable(params.value);
        this.cssClass = ensureObservable(params.cssClass || "");
        this.cssPrefix = ensureObservable(params.cssPrefix || "form");
        this.inputType = ensureObservable(params.inputType || "text");
        this.i18nPrefix = ensureObservable(params.i18nPrefix);
        this.showLabel = ensureObservable(params.showLabel == false ? false : true);
        this.onFocus = ensureObservable(params.onFocus || "");
    }

    public cssClassField = ko.pureComputed(() =>
        "form-control " + this.cssPrefix() + "__input " + this.cssPrefix() + "__input--" + this.name() + " " + this.cssClass()
    )

    public labelKey = ko.pureComputed(() =>
        (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".label"
    );

    public placeholderKey = ko.pureComputed(() =>
        (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".placeholder"
    );

    public cssClassLabel = ko.pureComputed(() =>
        this.cssPrefix() + "__label " + this.cssPrefix() + "__label--" + this.name()
    );
}

let component: Config = {
    viewModel: (params: TextInputViewModelParams, componentInfo?: ComponentInfo) => new TextInputViewModel(params),
    template: <string>require('./text-input.html')
}

export default component;