import {ContentApi, DataApi} from "./generated";
import globalAxios from "axios";
import globalState from "../global-state";

globalAxios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (globalState.accessToken()) {
        config.headers['authorization'] = `Bearer ${globalState.accessToken()}`;
    }
    return config;
});

export const contentApi = new ContentApi(config.axiosOptions, config.endpoint);

export const dataApi = new DataApi(config.axiosOptions, config.endpoint);
