import * as ko from 'knockout';
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.colVis.js";
//require('imports-loader?define=>false!datatables.net-buttons/js/buttons.colVis.js');
// Disable AMD support in buttons.html5.js and supply factory arguments so
// we do not introduce a global dependency on jszip.
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.html5.js')(window, $, require("jszip"));
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive-bs4";

// see https://ayerdi.tech/knockout-datatables-binding/

ko.bindingHandlers.dataTablesForEach = {
    page: 0,
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var binding = ko.utils.unwrapObservable(valueAccessor());

        ko.unwrap(binding.data);

        if (binding.options.paging) {
            binding.data.subscribe(function (changes) {
                var table = $(element).closest('table').DataTable();
                ko.bindingHandlers.dataTablesForEach.page = table.page();
                table.destroy();
            }, null, 'arrayChange');
        }

        var nodes = Array.prototype.slice.call(element.childNodes, 0);
        ko.utils.arrayForEach(nodes, function (node) {
            if (node && node.nodeType !== 1) {
                node.parentNode.removeChild(node);
            }
        });

        return ko.bindingHandlers.foreach.init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var binding = ko.utils.unwrapObservable(valueAccessor()),
            key = 'DataTablesForEach_Initialized';

        ko.unwrap(binding.data);

        // in order to check below, we need to make sure we know the size of the table
        // before the data is loaded
        var previousTableSize = $(element).closest('table').find("tr").length;

        // destroy table anyway, to support computed observables for data tables
        // the original binding destroyed the dt only if the paging option was set to false.
        var table = $(element).closest('table').DataTable();
        table.destroy();

        ko.bindingHandlers.foreach.update(element, valueAccessor, allBindings, viewModel, bindingContext);

        table = $(element).closest('table').DataTable(binding.options);

        // if we're switching between tables of zero size to something greater than zero,
        // then we want to skip the processing here in order to avoid the library's internal exception
        if (binding.options.paging && !(previousTableSize === 1 && binding.data().length > 0)) {
            if (table.page.info().pages - ko.bindingHandlers.dataTablesForEach.page === 0) {
                table.page(--ko.bindingHandlers.dataTablesForEach.page).draw(false);
            } else {
                table.page(ko.bindingHandlers.dataTablesForEach.page).draw(false);
            }
        }

        if (!ko.utils.domData.get(element, key) && (binding.data || binding.length)) {
            ko.utils.domData.set(element, key, true);
        }

        return {
            controlsDescendantBindings: true
        };
    }
};