import * as ko from "knockout";
import {Observable} from "knockout";

export interface AccessToken  {
    permissions: string[];
}


/**
 * Global observables used trough out the application.
 */
export class GlobalState {
    /**
     * Triggers the loading indicator.
     */
    public loading: Observable<boolean> = ko.observable(true);

    /**
     * Triggers scrollToError.
     */
    public hasErrors: Observable<boolean> = ko.observable(false);

    /**
     * Auth0 access token
     */
    public accessToken: Observable<string> = ko.observable();

    public decodedToken: Observable<AccessToken> = ko.observable();

    /**
     * is the user Doccheck logged in.
     */
    public docChecked:  Observable<boolean> = ko.observable(false);

    /**
     * Is the Doccheck view active (Fachbereich) or the public view.
     */
    public docCheckedView:  Observable<boolean> = ko.observable(false);
}

/**
 * Exports a singleton state object.
 */
export default new GlobalState();
