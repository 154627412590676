// as long as knockoutMappingType is not acutally called, only the definitions
// are available here, but the module is not imported. The module is loaded
// by the System.import call below, showing how to achieve lazy loading
// with TypeScript.
import * as $ from 'jquery';
import {postbox} from "../util/postbox";


class NotificationsViewModel {

    private timeoutId: any;
    public postbox = postbox;

    // noinspection JSUnusedGlobalSymbols, JSUnusedLocalSymbols
    public clearErrors = (data, event) => {
        this.postbox.clearErrors();
    };

    // noinspection JSUnusedGlobalSymbols, JSUnusedLocalSymbols
    public clearInfos = (data, event) => {
        this.postbox.clearInfos();
    };

    // noinspection JSUnusedGlobalSymbols, JSUnusedLocalSymbols
    public clearSuccesses = (data, event) => {
        this.postbox.clearSuccesses();
    };

    // noinspection JSUnusedGlobalSymbols, JSUnusedLocalSymbols
    public showMessage = (elem) => {
        $(elem).filter("li").hide().slideDown();
        this.setClearTimeout();
    };

    // noinspection JSUnusedGlobalSymbols, JSUnusedLocalSymbols
    public hideMessage = (elem) => {
        $(elem).filter("li").slideUp(function() {
            $(this).remove();
        });
    };

    private setClearTimeout = () => {
        if (this.timeoutId != null) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        let self = this;
        this.timeoutId = setTimeout(function() {
            self.postbox.clearInfos();
            self.postbox.clearSuccesses();
            self.postbox.clearErrors();
        }, 4000);
    }
}

// noinspection JSUnusedGlobalSymbols
export default {
    viewModel: NotificationsViewModel,
    template: <string>require('./notifications.html')
};
