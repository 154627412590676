/* tslint:disable */
/* eslint-disable */
/**
 * XEOMOTION_API
 * XEOMOTION API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jan.illetschko@3kraft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'housenumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'staircase'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'doornumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface DepartmentDto
 */
export interface DepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'website'?: string;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof DepartmentDto
     */
    'institution'?: InstitutionDto;
}
/**
 * 
 * @export
 * @interface InstitutionDto
 */
export interface InstitutionDto {
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    'type'?: InstitutionDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionDto
     */
    'barrierFree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionDto
     */
    'homeVisitsOnly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionDto
     */
    'hideAddress'?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof InstitutionDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {Array<DepartmentDto>}
     * @memberof InstitutionDto
     */
    'departments'?: Array<DepartmentDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum InstitutionDtoTypeEnum {
    Hospital = 'HOSPITAL',
    Therapy = 'THERAPY',
    Therapist = 'THERAPIST',
    Privat = 'PRIVAT'
}

/**
 * 
 * @export
 * @interface OpeningHours
 */
export interface OpeningHours {
    /**
     * 
     * @type {string}
     * @memberof OpeningHours
     */
    'day'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpeningHours
     */
    'hours'?: string;
}
/**
 * 
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'type'?: PersonDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'gender'?: PersonDtoGenderEnum;
    /**
     * Akademischer Titel
     * @type {string}
     * @memberof PersonDto
     */
    'title'?: string;
    /**
     * Postgrade Titel
     * @type {string}
     * @memberof PersonDto
     */
    'titlePostgrade'?: string;
    /**
     * Verliehener Titel
     * @type {string}
     * @memberof PersonDto
     */
    'titleConferred'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonDto
     */
    'certified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonDto
     */
    'languages'?: Array<string>;
    /**
     * 
     * @type {ProfileImageDto}
     * @memberof PersonDto
     */
    'profileImage'?: ProfileImageDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PersonDtoTypeEnum {
    Doctor = 'DOCTOR',
    Therapist = 'THERAPIST'
}
/**
    * @export
    * @enum {string}
    */
export enum PersonDtoGenderEnum {
    Female = 'FEMALE',
    Male = 'MALE'
}

/**
 * used to upload profile image in a post or put request without using multipart file uploads
 * @export
 * @interface ProfileImageDto
 */
export interface ProfileImageDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileImageDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileImageDto
     */
    'filename'?: string;
    /**
     * the mime type of the uploaded file
     * @type {string}
     * @memberof ProfileImageDto
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileImageDto
     */
    'modified'?: string;
    /**
     * base64 encoded file data
     * @type {string}
     * @memberof ProfileImageDto
     */
    'data'?: string;
}
/**
 * Array of search result items will be delivered to the client
 * @export
 * @interface SearchResultItemDto
 */
export interface SearchResultItemDto {
    /**
     * 
     * @type {string}
     * @memberof SearchResultItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {PersonDto}
     * @memberof SearchResultItemDto
     */
    'person'?: PersonDto;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof SearchResultItemDto
     */
    'department'?: DepartmentDto;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof SearchResultItemDto
     */
    'institution'?: InstitutionDto;
    /**
     * Fachgebiete
     * @type {Array<string>}
     * @memberof SearchResultItemDto
     */
    'areasOfSubject'?: Array<string>;
    /**
     * Schwerpunkte
     * @type {Array<string>}
     * @memberof SearchResultItemDto
     */
    'keyAspects'?: Array<string>;
    /**
     * Krankenkassenverträge
     * @type {Array<string>}
     * @memberof SearchResultItemDto
     */
    'insurances'?: Array<SearchResultItemDtoInsurancesEnum>;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItemDto
     */
    'medicate'?: SearchResultItemDtoMedicateEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItemDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItemDto
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItemDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItemDto
     */
    'website'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResultItemDto
     */
    'hideEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResultItemDto
     */
    'approved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResultItemDto
     */
    'published'?: boolean;
    /**
     * Öffnungszeiten, eine Zeile pro Tag und Uhrzeit
     * @type {Array<OpeningHours>}
     * @memberof SearchResultItemDto
     */
    'openingHours'?: Array<OpeningHours>;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchResultItemDtoInsurancesEnum {
    All = 'ALL',
    AllPrivat = 'ALL_PRIVAT',
    Wahlarzt = 'WAHLARZT',
    Wahltherapeut = 'WAHLTHERAPEUT',
    Auva = 'AUVA',
    Bvaeb = 'BVAEB',
    Kfa = 'KFA',
    None = 'NONE',
    Oegk = 'OEGK',
    Pva = 'PVA',
    Svs = 'SVS'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchResultItemDtoMedicateEnum {
    Adults = 'ADULTS',
    Children = 'CHILDREN',
    Both = 'BOTH'
}

/**
 * used to upload attachments data in a post or put request without using multipart file uploads
 * @export
 * @interface UploadDto
 */
export interface UploadDto {
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'filename'?: string;
    /**
     * the mime type of the uploaded file
     * @type {string}
     * @memberof UploadDto
     */
    'mimeType'?: string;
    /**
     * base64 encoded file data
     * @type {string}
     * @memberof UploadDto
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface WidgetDto
 */
export interface WidgetDto {
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    'type'?: WidgetDtoTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof WidgetDto
     */
    'content'?: object;
    /**
     * 
     * @type {Array<WidgetDto>}
     * @memberof WidgetDto
     */
    'children'?: Array<WidgetDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum WidgetDtoTypeEnum {
    Page = 'PAGE',
    WidgetContainer = 'WIDGET_CONTAINER',
    RichText = 'RICH_TEXT',
    Video = 'VIDEO',
    Image = 'IMAGE',
    Gallery = 'GALLERY',
    Cite = 'CITE',
    Countdown = 'COUNTDOWN',
    Empty = 'EMPTY'
}


/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget: async (widgetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('deleteWidget', 'widgetId', widgetId)
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get assets from asset store
         * @param {string} assetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssets', 'assetId', assetId)
            const localVarPath = `/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget: async (widgetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('getWidget', 'widgetId', widgetId)
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get widgets without a parent.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * performs a form upload
         * @param {any} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFormUpload: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postFormUpload', 'file', file)
            const localVarPath = `/formUploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * performs an upload
         * @param {Array<UploadDto>} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploads: async (body: Array<UploadDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postUploads', 'body', body)
            const localVarPath = `/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget: async (body: WidgetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postWidget', 'body', body)
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates a root widget.
         * @param {string} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWidget: async (widgetId: string, body: WidgetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('putWidget', 'widgetId', widgetId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putWidget', 'body', body)
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * deletes a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWidget(widgetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWidget(widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get assets from asset store
         * @param {string} assetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssets(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssets(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWidget(widgetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWidget(widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get widgets without a parent.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWidgets(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWidgets(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * performs a form upload
         * @param {any} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFormUpload(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFormUpload(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * performs an upload
         * @param {Array<UploadDto>} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploads(body: Array<UploadDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploads(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWidget(body: WidgetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWidget(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates a root widget.
         * @param {string} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWidget(widgetId: string, body: WidgetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWidget(widgetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * deletes a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget(widgetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWidget(widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * get assets from asset store
         * @param {string} assetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets(assetId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAssets(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * gets a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget(widgetId: string, options?: any): AxiosPromise<WidgetDto> {
            return localVarFp.getWidget(widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * get widgets without a parent.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets(limit?: number, options?: any): AxiosPromise<Array<WidgetDto>> {
            return localVarFp.getWidgets(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * performs a form upload
         * @param {any} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFormUpload(file: any, options?: any): AxiosPromise<string> {
            return localVarFp.postFormUpload(file, options).then((request) => request(axios, basePath));
        },
        /**
         * performs an upload
         * @param {Array<UploadDto>} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploads(body: Array<UploadDto>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.postUploads(body, options).then((request) => request(axios, basePath));
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget(body: WidgetDto, options?: any): AxiosPromise<WidgetDto> {
            return localVarFp.postWidget(body, options).then((request) => request(axios, basePath));
        },
        /**
         * updates a root widget.
         * @param {string} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWidget(widgetId: string, body: WidgetDto, options?: any): AxiosPromise<WidgetDto> {
            return localVarFp.putWidget(widgetId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * deletes a widget
     * @param {string} widgetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public deleteWidget(widgetId: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).deleteWidget(widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get assets from asset store
     * @param {string} assetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getAssets(assetId: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).getAssets(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets a widget
     * @param {string} widgetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getWidget(widgetId: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).getWidget(widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get widgets without a parent.
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getWidgets(limit?: number, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).getWidgets(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * performs a form upload
     * @param {any} file The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postFormUpload(file: any, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).postFormUpload(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * performs an upload
     * @param {Array<UploadDto>} body upload with meta data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postUploads(body: Array<UploadDto>, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).postUploads(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * creates a root widget.
     * @param {WidgetDto} body widget to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postWidget(body: WidgetDto, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).postWidget(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates a root widget.
     * @param {string} widgetId an id
     * @param {WidgetDto} body widget to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public putWidget(widgetId: string, body: WidgetDto, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).putWidget(widgetId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataApi - axios parameter creator
 * @export
 */
export const DataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a department
         * @param {string} departmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartment', 'departmentId', departmentId)
            const localVarPath = `/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes an institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInstitution: async (institutionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institutionId' is not null or undefined
            assertParamExists('deleteInstitution', 'institutionId', institutionId)
            const localVarPath = `/institutions/{institutionId}`
                .replace(`{${"institutionId"}}`, encodeURIComponent(String(institutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes a person
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('deletePerson', 'personId', personId)
            const localVarPath = `/persons/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes a search result item.
         * @param {string} personDepartmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonDepartment: async (personDepartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personDepartmentId' is not null or undefined
            assertParamExists('deletePersonDepartment', 'personDepartmentId', personDepartmentId)
            const localVarPath = `/search/{personDepartmentId}`
                .replace(`{${"personDepartmentId"}}`, encodeURIComponent(String(personDepartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a department
         * @param {string} departmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getDepartment', 'departmentId', departmentId)
            const localVarPath = `/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all departments.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments: async (dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dtoResponseType !== undefined) {
                localVarQueryParameter['dtoResponseType'] = dtoResponseType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitution: async (institutionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institutionId' is not null or undefined
            assertParamExists('getInstitution', 'institutionId', institutionId)
            const localVarPath = `/institutions/{institutionId}`
                .replace(`{${"institutionId"}}`, encodeURIComponent(String(institutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets the departments for an institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionDepartments: async (institutionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institutionId' is not null or undefined
            assertParamExists('getInstitutionDepartments', 'institutionId', institutionId)
            const localVarPath = `/institutions/{institutionId}/departments`
                .replace(`{${"institutionId"}}`, encodeURIComponent(String(institutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all institutions.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutions: async (dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dtoResponseType !== undefined) {
                localVarQueryParameter['dtoResponseType'] = dtoResponseType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a person
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getPerson', 'personId', personId)
            const localVarPath = `/persons/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all published and approved search result items.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonDepartments: async (dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dtoResponseType !== undefined) {
                localVarQueryParameter['dtoResponseType'] = dtoResponseType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a search result item
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonDepartmentsByPersonId: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getPersonDepartmentsByPersonId', 'personId', personId)
            const localVarPath = `/search/person/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all persons.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersons: async (dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)

            if (dtoResponseType !== undefined) {
                localVarQueryParameter['dtoResponseType'] = dtoResponseType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a profile image
         * @param {string} imageId an id
         * @param {string} modified a timestamp of the last modification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileImage: async (imageId: string, modified: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('getProfileImage', 'imageId', imageId)
            // verify required parameter 'modified' is not null or undefined
            assertParamExists('getProfileImage', 'modified', modified)
            const localVarPath = `/profileImage/{imageId}/{modified}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)))
                .replace(`{${"modified"}}`, encodeURIComponent(String(modified)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a department.
         * @param {DepartmentDto} departmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartment: async (departmentDto: DepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentDto' is not null or undefined
            assertParamExists('postDepartment', 'departmentDto', departmentDto)
            const localVarPath = `/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a institution.
         * @param {InstitutionDto} institutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInstitution: async (institutionDto: InstitutionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institutionDto' is not null or undefined
            assertParamExists('postInstitution', 'institutionDto', institutionDto)
            const localVarPath = `/institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(institutionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a person.
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPerson: async (personDto: PersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personDto' is not null or undefined
            assertParamExists('postPerson', 'personDto', personDto)
            const localVarPath = `/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a  search result item (person department assignment).
         * @param {SearchResultItemDto} searchResultItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPersonDepartment: async (searchResultItemDto: SearchResultItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchResultItemDto' is not null or undefined
            assertParamExists('postPersonDepartment', 'searchResultItemDto', searchResultItemDto)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchResultItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * performs a profile image upload
         * @param {string} personId an id
         * @param {ProfileImageDto} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileImage: async (personId: string, body: ProfileImageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('postProfileImage', 'personId', personId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postProfileImage', 'body', body)
            const localVarPath = `/persons/{personId}/imageUpload`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates department.
         * @param {string} departmentId an id
         * @param {DepartmentDto} departmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDepartment: async (departmentId: string, departmentDto: DepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('putDepartment', 'departmentId', departmentId)
            // verify required parameter 'departmentDto' is not null or undefined
            assertParamExists('putDepartment', 'departmentDto', departmentDto)
            const localVarPath = `/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates institution.
         * @param {string} institutionId an id
         * @param {InstitutionDto} institutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInstitution: async (institutionId: string, institutionDto: InstitutionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institutionId' is not null or undefined
            assertParamExists('putInstitution', 'institutionId', institutionId)
            // verify required parameter 'institutionDto' is not null or undefined
            assertParamExists('putInstitution', 'institutionDto', institutionDto)
            const localVarPath = `/institutions/{institutionId}`
                .replace(`{${"institutionId"}}`, encodeURIComponent(String(institutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(institutionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates person.
         * @param {string} personId an id
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPerson: async (personId: string, personDto: PersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('putPerson', 'personId', personId)
            // verify required parameter 'personDto' is not null or undefined
            assertParamExists('putPerson', 'personDto', personDto)
            const localVarPath = `/persons/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates a search result item.
         * @param {string} personDepartmentId an id
         * @param {SearchResultItemDto} searchResultItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPersonDepartment: async (personDepartmentId: string, searchResultItemDto: SearchResultItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personDepartmentId' is not null or undefined
            assertParamExists('putPersonDepartment', 'personDepartmentId', personDepartmentId)
            // verify required parameter 'searchResultItemDto' is not null or undefined
            assertParamExists('putPersonDepartment', 'searchResultItemDto', searchResultItemDto)
            const localVarPath = `/search/{personDepartmentId}`
                .replace(`{${"personDepartmentId"}}`, encodeURIComponent(String(personDepartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchResultItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataApi - functional programming interface
 * @export
 */
export const DataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataApiAxiosParamCreator(configuration)
    return {
        /**
         * deletes a department
         * @param {string} departmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartment(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartment(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * deletes an institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInstitution(institutionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInstitution(institutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * deletes a person
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * deletes a search result item.
         * @param {string} personDepartmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePersonDepartment(personDepartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePersonDepartment(personDepartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets a department
         * @param {string} departmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartment(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartment(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all departments.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartments(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartments(dtoResponseType, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets a institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstitution(institutionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitution(institutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets the departments for an institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstitutionDepartments(institutionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitutionDepartments(institutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all institutions.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstitutions(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstitutionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitutions(dtoResponseType, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets a person
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all published and approved search result items.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonDepartments(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchResultItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonDepartments(dtoResponseType, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets a search result item
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonDepartmentsByPersonId(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchResultItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonDepartmentsByPersonId(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all persons.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersons(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersons(dtoResponseType, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a profile image
         * @param {string} imageId an id
         * @param {string} modified a timestamp of the last modification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileImage(imageId: string, modified: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileImage(imageId, modified, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * creates a department.
         * @param {DepartmentDto} departmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDepartment(departmentDto: DepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDepartment(departmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * creates a institution.
         * @param {InstitutionDto} institutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInstitution(institutionDto: InstitutionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInstitution(institutionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * creates a person.
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPerson(personDto: PersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPerson(personDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * creates a  search result item (person department assignment).
         * @param {SearchResultItemDto} searchResultItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPersonDepartment(searchResultItemDto: SearchResultItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPersonDepartment(searchResultItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * performs a profile image upload
         * @param {string} personId an id
         * @param {ProfileImageDto} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProfileImage(personId: string, body: ProfileImageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProfileImage(personId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates department.
         * @param {string} departmentId an id
         * @param {DepartmentDto} departmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDepartment(departmentId: string, departmentDto: DepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDepartment(departmentId, departmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates institution.
         * @param {string} institutionId an id
         * @param {InstitutionDto} institutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInstitution(institutionId: string, institutionDto: InstitutionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInstitution(institutionId, institutionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates person.
         * @param {string} personId an id
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPerson(personId: string, personDto: PersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPerson(personId, personDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates a search result item.
         * @param {string} personDepartmentId an id
         * @param {SearchResultItemDto} searchResultItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPersonDepartment(personDepartmentId: string, searchResultItemDto: SearchResultItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPersonDepartment(personDepartmentId, searchResultItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataApi - factory interface
 * @export
 */
export const DataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataApiFp(configuration)
    return {
        /**
         * deletes a department
         * @param {string} departmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment(departmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartment(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * deletes an institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInstitution(institutionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInstitution(institutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * deletes a person
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(personId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * deletes a search result item.
         * @param {string} personDepartmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonDepartment(personDepartmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePersonDepartment(personDepartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * gets a department
         * @param {string} departmentId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment(departmentId: string, options?: any): AxiosPromise<DepartmentDto> {
            return localVarFp.getDepartment(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * get all departments.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: any): AxiosPromise<Array<DepartmentDto>> {
            return localVarFp.getDepartments(dtoResponseType, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * gets a institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitution(institutionId: string, options?: any): AxiosPromise<InstitutionDto> {
            return localVarFp.getInstitution(institutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * gets the departments for an institution
         * @param {string} institutionId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionDepartments(institutionId: string, options?: any): AxiosPromise<Array<DepartmentDto>> {
            return localVarFp.getInstitutionDepartments(institutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * get all institutions.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutions(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: any): AxiosPromise<Array<InstitutionDto>> {
            return localVarFp.getInstitutions(dtoResponseType, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * gets a person
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson(personId: string, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.getPerson(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * get all published and approved search result items.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonDepartments(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: any): AxiosPromise<Array<SearchResultItemDto>> {
            return localVarFp.getPersonDepartments(dtoResponseType, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * gets a search result item
         * @param {string} personId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonDepartmentsByPersonId(personId: string, options?: any): AxiosPromise<Array<SearchResultItemDto>> {
            return localVarFp.getPersonDepartmentsByPersonId(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * get all persons.
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersons(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: any): AxiosPromise<Array<PersonDto>> {
            return localVarFp.getPersons(dtoResponseType, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get a profile image
         * @param {string} imageId an id
         * @param {string} modified a timestamp of the last modification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileImage(imageId: string, modified: string, options?: any): AxiosPromise<any> {
            return localVarFp.getProfileImage(imageId, modified, options).then((request) => request(axios, basePath));
        },
        /**
         * creates a department.
         * @param {DepartmentDto} departmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartment(departmentDto: DepartmentDto, options?: any): AxiosPromise<DepartmentDto> {
            return localVarFp.postDepartment(departmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * creates a institution.
         * @param {InstitutionDto} institutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInstitution(institutionDto: InstitutionDto, options?: any): AxiosPromise<InstitutionDto> {
            return localVarFp.postInstitution(institutionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * creates a person.
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPerson(personDto: PersonDto, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.postPerson(personDto, options).then((request) => request(axios, basePath));
        },
        /**
         * creates a  search result item (person department assignment).
         * @param {SearchResultItemDto} searchResultItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPersonDepartment(searchResultItemDto: SearchResultItemDto, options?: any): AxiosPromise<SearchResultItemDto> {
            return localVarFp.postPersonDepartment(searchResultItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * performs a profile image upload
         * @param {string} personId an id
         * @param {ProfileImageDto} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileImage(personId: string, body: ProfileImageDto, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.postProfileImage(personId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * updates department.
         * @param {string} departmentId an id
         * @param {DepartmentDto} departmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDepartment(departmentId: string, departmentDto: DepartmentDto, options?: any): AxiosPromise<DepartmentDto> {
            return localVarFp.putDepartment(departmentId, departmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * updates institution.
         * @param {string} institutionId an id
         * @param {InstitutionDto} institutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInstitution(institutionId: string, institutionDto: InstitutionDto, options?: any): AxiosPromise<InstitutionDto> {
            return localVarFp.putInstitution(institutionId, institutionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * updates person.
         * @param {string} personId an id
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPerson(personId: string, personDto: PersonDto, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.putPerson(personId, personDto, options).then((request) => request(axios, basePath));
        },
        /**
         * updates a search result item.
         * @param {string} personDepartmentId an id
         * @param {SearchResultItemDto} searchResultItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPersonDepartment(personDepartmentId: string, searchResultItemDto: SearchResultItemDto, options?: any): AxiosPromise<SearchResultItemDto> {
            return localVarFp.putPersonDepartment(personDepartmentId, searchResultItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataApi - object-oriented interface
 * @export
 * @class DataApi
 * @extends {BaseAPI}
 */
export class DataApi extends BaseAPI {
    /**
     * deletes a department
     * @param {string} departmentId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public deleteDepartment(departmentId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).deleteDepartment(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deletes an institution
     * @param {string} institutionId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public deleteInstitution(institutionId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).deleteInstitution(institutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deletes a person
     * @param {string} personId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public deletePerson(personId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).deletePerson(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deletes a search result item.
     * @param {string} personDepartmentId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public deletePersonDepartment(personDepartmentId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).deletePersonDepartment(personDepartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets a department
     * @param {string} departmentId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getDepartment(departmentId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getDepartment(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all departments.
     * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getDepartments(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getDepartments(dtoResponseType, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets a institution
     * @param {string} institutionId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getInstitution(institutionId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getInstitution(institutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets the departments for an institution
     * @param {string} institutionId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getInstitutionDepartments(institutionId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getInstitutionDepartments(institutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all institutions.
     * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getInstitutions(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getInstitutions(dtoResponseType, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets a person
     * @param {string} personId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getPerson(personId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getPerson(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all published and approved search result items.
     * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getPersonDepartments(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getPersonDepartments(dtoResponseType, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets a search result item
     * @param {string} personId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getPersonDepartmentsByPersonId(personId: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getPersonDepartmentsByPersonId(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all persons.
     * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getPersons(dtoResponseType?: 'full' | 'slim' | 'ref', limit?: number, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getPersons(dtoResponseType, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a profile image
     * @param {string} imageId an id
     * @param {string} modified a timestamp of the last modification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getProfileImage(imageId: string, modified: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getProfileImage(imageId, modified, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * creates a department.
     * @param {DepartmentDto} departmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public postDepartment(departmentDto: DepartmentDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).postDepartment(departmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * creates a institution.
     * @param {InstitutionDto} institutionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public postInstitution(institutionDto: InstitutionDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).postInstitution(institutionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * creates a person.
     * @param {PersonDto} personDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public postPerson(personDto: PersonDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).postPerson(personDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * creates a  search result item (person department assignment).
     * @param {SearchResultItemDto} searchResultItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public postPersonDepartment(searchResultItemDto: SearchResultItemDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).postPersonDepartment(searchResultItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * performs a profile image upload
     * @param {string} personId an id
     * @param {ProfileImageDto} body upload with meta data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public postProfileImage(personId: string, body: ProfileImageDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).postProfileImage(personId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates department.
     * @param {string} departmentId an id
     * @param {DepartmentDto} departmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public putDepartment(departmentId: string, departmentDto: DepartmentDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).putDepartment(departmentId, departmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates institution.
     * @param {string} institutionId an id
     * @param {InstitutionDto} institutionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public putInstitution(institutionId: string, institutionDto: InstitutionDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).putInstitution(institutionId, institutionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates person.
     * @param {string} personId an id
     * @param {PersonDto} personDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public putPerson(personId: string, personDto: PersonDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).putPerson(personId, personDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates a search result item.
     * @param {string} personDepartmentId an id
     * @param {SearchResultItemDto} searchResultItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public putPersonDepartment(personDepartmentId: string, searchResultItemDto: SearchResultItemDto, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).putPersonDepartment(personDepartmentId, searchResultItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


