import globalState from "./global-state";
import {postbox} from "./components/util/postbox";
import * as ko from "knockout";
import {Observable, PureComputed} from "knockout";
import 'bootstrap';
import 'knockstrap';
import 'knockout.validation'
import 'knockout.autocomplete/lib/knockout.autocomplete';
import 'knockout.autocomplete/lib/knockout.autocomplete.css';
import "./bindings/knockout-date-bindings";
import "./bindings/knockout-select2";
import "./bindings/knockout-date-picker";
import "./bindings/knockout-foreach-groups";
import "./bindings/knockout-linkify";
import "./bindings/knockout-checked-inverse";
import "./bindings/enterkey"
import 'knockout-file-bindings';
import "./bindings/knockout.tab";
import * as moment from "moment";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import scrollToError from "./bindings/scrollToError";
import enterkey from "./bindings/enterkey";
import blur from "./bindings/blur";
import i18nextko from './bindings/i18nko'
import {autobind, computed} from "knockout-decorators";
import {Auth0Client} from "@auth0/auth0-spa-js";
import jwtDecode from "jwt-decode";
import * as Cookies from "js-cookie";
import {createConfirmModal} from "./components/elements/modal/modal";

export const auth0 = new Auth0Client({
    domain: config.auth0Options.domain,
    clientId: config.auth0Options.clientId,
    cacheLocation: config.auth0Options.cacheLocation,
    useRefreshTokens: config.auth0Options.useRefreshTokens
});

/**
 * The root view model of the application.
 */
class AppViewModel {

    /**
     * Shows/Hides the loading indicator
     */
    public loading = globalState.loading;

    /**
     * Triggers scrollToError binding.
     */
    public hasErrors: PureComputed<boolean>;

    constructor() {
        /**
         * Scroll to error if postbox has errors or globalState.hasErrors (to trigger the scrolling manually)
         */
        this.hasErrors = ko.pureComputed(() => {
            return postbox.errors().length > 0 || globalState.hasErrors();
        }, this).extend({rateLimit: 500});

        globalState.docChecked(App.isDocChecked());
        globalState.docCheckedView(App.isDocCheckedView());
    }

    /**
     * Is the user logged in with a doccheck login?
     */
    @computed
    public get docChecked() {
        return globalState.docChecked();
    }

    /**
     * Is the 'Fachbereich' view for doc checked users active?
     */
    @computed
    public get docCheckedView() {
        return this.docChecked && globalState.docCheckedView();
    }

    @autobind
    public toggleDocCheckedView() {
        globalState.docCheckedView(!globalState.docCheckedView());
        App.setDocCheckedView(globalState.docCheckedView());
        return Router.update("/", { force: true});
    }

    @computed
    public get appTitle() {
        return globalState.docCheckedView() === true ? "XEOMOTION" : "NEUROTREFFPUNKT";
    }

    @computed
    public get logoPath() {
        return globalState.docCheckedView() === true ? "images/xeomotion-logo-subline.png" : "images/neurotreffpunkt-logo-subline.png";
    }

    @computed
    public get bannerCssClass() {
        return globalState.docCheckedView() === true ? "row--full-width-hero--docview" : "";
    }

    @computed
    public get isAdmin(): boolean {
        return globalState.decodedToken() && globalState.decodedToken().permissions.indexOf('access:admin') > -1;
    }

    @computed
    public get isLoggedIn(): boolean {
        return !!globalState.decodedToken();
    }

    /**
     * Translates a key.
     *
     * @param key - see https://www.i18next.com/overview/api#t
     * @param options - see https://www.i18next.com/translation-function/interpolation
     */
    public i18n(key: String, options?: object) {
        return i18nextko.t(key, options);
    }

    public login() {
        return auth0.loginWithRedirect({
            authorizationParams: {
                ...config.auth0Options.authorizationParams,
                redirect_uri: `${window.location.origin}/app`,
            },
            appState: {
                returnTo: window.location.pathname
            }
        });
    }

    public logout() {
        return auth0.logout({
            logoutParams: {
                returnTo: `${window.location.origin}/app`
            }
        });
    }

    /**
     * Default tab options
     * Scroll o tab content on small devices.
     */
    public tabOptions() {
        return {
            isDefault: false,
            onShow: function(tabElement, contentElement) {
                const width = window.innerWidth;
                if (width && Number.isInteger(width) && width < 768) {
                    contentElement.scrollIntoView();
                }
            }
        }
    }

    public scrollToTabContent(tabElement, contentElement) {
        const width = window.innerWidth;
        if(width && Number.isInteger(width) && width < 768) {
            console.log("event", tabElement, contentElement);
            contentElement.scrollIntoView();
        }
    }

    /**
     * Display a disclaimer on external links.
     * @param data
     * @param event
     */
    public disclaimerLink(data, event) {
        const href = event.currentTarget.getAttribute('href');
        if(!href || href == '') {
            return Promise.resolve();
        } else {
            const target = event.currentTarget.getAttribute('target');
            return createConfirmModal(
                i18nextko.t("disclaimer.confirmText"), i18nextko.t("disclaimer.confirmTitle"),
                i18nextko.t("disclaimer.ok"),
                i18nextko.t("disclaimer.cancel")
            )
                .then(() => {
                    if(target && target !== "") {
                        window.open(href, target);
                    } else {
                        window.open(href);
                    }
                    return Promise.resolve();
                })
                .catch(err => {
                    // close dialog
                })
        }
    }
}

/**
 * The application controller.
 */
export class App {

    /**
     * Doc check cookie name.
     */
    static docCheckedCookieName = "docc";

    /**
     * Doc check view cookie name.
     */
    static docCheckedViewCookieName = "doccview";

    /**
     * The root view model instance.
     */
    public viewModel: AppViewModel;

    /**
     * Initializes the root view model.
     */
    constructor() {
        this.viewModel = new AppViewModel();
    }

    private init(): Promise<void> {
        return auth0.handleRedirectCallback()
            .then(redirectLoginResult => {
                console.debug("app: successfully logged in");

                // restore returnTo appState, if set
                history.replaceState({}, '',
                    redirectLoginResult.appState && redirectLoginResult.appState.returnTo ?
                        redirectLoginResult.appState.returnTo : window.location.href
                );

                return Promise.resolve();
            })
            .catch(() => {
                console.debug("app: checking logged in", `${window.location.origin}/app`);
            })
            .finally(() => {
                return auth0
                    .getTokenSilently({
                        authorizationParams: {
                            ...config.auth0Options.authorizationParams,
                            redirect_uri: `${window.location.origin}/app`,
                        },
                    })
                    .then(token => {
                        globalState.accessToken(token);
                        globalState.decodedToken(jwtDecode(token));
                        console.debug("app: successfully got token", globalState.accessToken(), globalState.decodedToken());
                    })
                    .catch(() =>
                        console.debug("app: not logged in"))
                    .finally(() => {
                        return this._init();
                    });
            });
    }

    /**
     * Initializes the app:
     * - registers custom events: headerInitialized
     * - sets up the ko-component-router for navigation between pages
     * - registers loading and scrollTop router middleware
     * - registers additional binding handlers: scrollToError
     * - registers custom components: text-input
     * - loads translations: i18next
     * - initializes ko validation
     * - applies bindings to root view model
     */
    private _init(): Promise<void> {
        moment.locale('de');

        console.debug('app: router base', '/' + config.publicPath || '');
        //setup router
        Router.setConfig({
            base: '/' + config.publicPath || ''
        });

        Router.useRoutes({
            '/': [App.loadComponent('home/home.ts')],
            '/service': [App.loadComponent('service/service.ts')],
            '/faqs': [App.loadComponent('faqs/faqs.ts')],
            '/krankheitsbilder': [App.loadComponent('info/info.ts')],
            '/links': [App.loggedInDoccheckMiddleware, App.loadComponent('links/links.ts')],
            '/doccheck/login': [App.loadComponent('doccheck/login.ts')],
            '/doccheck/success': [App.loadComponent('doccheck/success.ts')],
            '/selfservice/:id': [App.loadComponent('admin/person/person.ts')],
            '/admin/location': [App.loggedIn, App.loadComponent('admin/location/location.ts')],
            '/admin/personen': [App.loggedIn, App.loadComponent('admin/persons/persons.ts')],
            '/admin/personen/neu': [App.loggedIn, App.loadComponent('admin/person/person.ts')],
            '/admin/personen/:id': [App.loggedIn, App.loadComponent('admin/person/person.ts')],
            '/admin/abteilung/neu': [App.loggedIn, App.loadComponent('admin/department/department.ts')],
            '/admin/abteilung/:id': [App.loggedIn, App.loadComponent('admin/department/department.ts')],
            '/admin/institutionen': [App.loggedIn, App.loadComponent('admin/institutions/institutions.ts')],
            '/admin/institutionen/neu': [App.loggedIn, App.loadComponent('admin/institution/institution.ts')],
            '/admin/institutionen/:id': [App.loggedIn, App.loadComponent('admin/institution/institution.ts')],
            '/admin': [App.loggedIn, App.loadComponent('admin/institutions/institutions.ts')]
        });

        Router.use(App.loadingMiddleware);
        Router.use(App.scrollTopMiddlware);
        Router.use(App.anchorLinksMiddlware);
        Router.use(App.hideResponsiveMenuMiddleware)

        // register bindings
        ko.bindingHandlers.scrollToError = scrollToError;
        ko.bindingHandlers.enterkey = enterkey;
        ko.bindingHandlers.blur = blur;

        // register components
        ko.components.register('text-input',
            require('./components/widgets/text-input').default);
        ko.components.register('text-area',
            require('./components/widgets/text-area').default);
        ko.components.register('notifications',
            require('./components/notifications/notifications').default);
        ko.components.register('menu',
            require('./components/elements/menu/menu').default);
        ko.components.register('footer',
            require('./components/elements/footer/footer').default);

        // register validation extenders
        ko.validation.rules['confirmed'] = {
            validator: function (val, expected) {
                return val === expected;
            },
            message: 'The field must by checked.'
        };
        ko.validation.registerExtenders();

        // localizations and validations
        return this.loadTranslations()
            .then(() => {
                // initialize validation
                ko.validation.init({
                    insertMessages: false,
                    grouping: {
                        deep: true,
                        live: true,
                        observable: true
                    },
                    messagesOnModified: true
                }, true);
                ko.validation.localize(i18nextko.t('validation', {returnObjects: true})());

                ko.applyBindings(this.viewModel, document.body);
            });
    }

    /**
     * Loads and registers (if not done before) a component. Used be the router to load pages.
     * @param componentName the component name
     * @returns Promise<void> a Promise
     */
    static loadComponent(componentName: string) {
        return (ctx: Context) => {
            console.debug("app: loading component " + componentName);

            return import("./pages/" + componentName)
                .then(
                    (loadedComponent) => {
                        let loadedComponentDefinition = <KnockoutLazyPageDefinition>loadedComponent.default;
                        console.debug("app: loaded component " + componentName);

                        if (!ko.components.isRegistered(loadedComponentDefinition.componentName)) {
                            ko.components.register(loadedComponentDefinition.componentName, loadedComponentDefinition);
                            console.debug("app: registered component " + loadedComponentDefinition.componentName);
                        }

                        let loaderPromise;
                        if (loadedComponentDefinition.loader) {
                            loaderPromise = loadedComponentDefinition.loader(ctx);

                        } else {
                            loaderPromise = Promise.resolve();
                        }

                        return loaderPromise.then(() => {
                            console.debug("app: setting ctx.router.component to component " +
                                loadedComponentDefinition.componentName);
                            ctx.route.component = loadedComponentDefinition.componentName;

                            return Promise.resolve();
                        });
                    }
                ).catch((error) => {
                    console.error("app: load failed", error);
                    if (error.status && (error.status == 404 || error.status == 500)) {
                        postbox.addError(`load.status.${error.status}`, {error: error}, error);
                    } else {
                        postbox.addError("load.failed", {error: error}, error);
                    }
                    globalState.loading(false);

                    return Promise.reject(error);
                });
        };
    }

    /**
     * Page loading indicator router middleware.
     */
    static loadingMiddleware() {

        return {
            beforeRender() {
                globalState.loading(true);
            },
            afterRender() {
                globalState.loading(false);
            }
        };
    }

    /**
     * Scroll to top after page load middleware
     */
    static scrollTopMiddlware() {

        return {
            beforeRender() {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto'
                });
            }
        };
    }

    /**
     * Scroll to anchor links after page load middleware.
     */
    static anchorLinksMiddlware() {
        return {
            afterRender() {
                const hash = (location.pathname + location.hash)
                    .replace(/#!/, '')
                    .replace(/^[^#]+#?/, '');
                if (hash) {
                    const anchor = document.getElementById(hash);
                    if (anchor !== null) {
                        const y = anchor.offsetTop;
                        window.scrollTo(0, y)
                    } else {
                        console.warn('app: anchorLinksMiddlware:',
                            `Navigated to page with #${hash}, but no element with id ${hash} found.`);
                    }
                }
            }
        };
    }

    /**
     * Hide the responsive menu if it's opened.
     */
    static hideResponsiveMenuMiddleware() {
        return {
            afterRender() {
                const toggle = document.getElementById("navbar-toggler");
                const attrExpanded = toggle ? toggle.getAttribute('aria-expanded') : false;
                if (attrExpanded && attrExpanded === 'true') {
                    toggle.click();
                }
            }
        }
    }


    /**
     * Initialize translations.
     * @returns Promise<void> a promise when the translations have been laoded
     */
    loadTranslations() {
        const language = "de";
        const english = require('../l10n/en.json');

        return import('../l10n/' + language + '.json')
            .catch(
                (err) => {
                    console.error('app: loading translations failed', err);
                    let resourceStore = {
                        en: english
                    };
                    i18nextko.init({
                        compatibilityAPI: 'v3',
                        lng: language || 'en',
                        fallbackLng: 'en',
                        resources: resourceStore
                    });
                }
            )
            .then(
                (targetLanguage) => {
                    let resourceStore = {
                        en: english
                    };
                    resourceStore[language] = targetLanguage;
                    i18nextko.init({
                        compatibilityAPI: 'v3',
                        lng: language || 'en',
                        fallbackLng: 'en',
                        resources: resourceStore
                    });
                }
            );
    }

    static loggedIn() {
        return {
            beforeRender() {
                if(!!globalState.decodedToken()) {
                    return Promise.resolve();
                }
                return Promise.reject().finally(() => auth0.loginWithRedirect({
                    authorizationParams: {
                        ...config.auth0Options.authorizationParams,
                        redirect_uri: `${window.location.origin}/app`,
                    },
                    appState: {
                        returnTo: window.location.pathname
                    }
                }));
            }
        }
    }

    static isDocChecked():boolean {
        const docCheckedCookie = Cookies.get(App.docCheckedCookieName);
        return docCheckedCookie && docCheckedCookie === "true" ? true : false;
    }

    static setDocChecked(checked: boolean): boolean {
        if(checked) {
            Cookies.set(App.docCheckedCookieName, "true", { sameSite: 'strict', secure: true });
        } else {
            Cookies.remove(App.docCheckedCookieName);
        }
        return checked;
    }

    static isDocCheckedView():boolean {
        const docCheckedViewCookie = Cookies.get(App.docCheckedViewCookieName);
        return docCheckedViewCookie && docCheckedViewCookie === "true" ? true : false;
    }

    static setDocCheckedView(checked: boolean): boolean {
        if(checked) {
            Cookies.set(App.docCheckedViewCookieName, "true", { sameSite: 'strict', secure: true });
        } else {
            Cookies.remove(App.docCheckedViewCookieName);
        }
        return checked;
    }

    static loggedInDoccheck() {
        const docCheckedCookie = Cookies.get(App.docCheckedCookieName);
        return docCheckedCookie && docCheckedCookie === "true" ? true : false;
    }

    static loggedInDoccheckMiddleware() {
        return {
            beforeRender() {
                if(!App.loggedInDoccheck()) {
                    Router.update("/doccheck/login", { force: true});
                    return Promise.reject()
                }
            }
        }
    }

    /**
     * Get enum values as option list.
     *
     * @param theEnum
     * @param i18nPrefix
     */
    static enumOptions(theEnum, i18nPrefix, sorted = true) {
        const options = Object.keys(theEnum)
            .filter(key => key.toLowerCase() != "unknown")
            .map(key => ({
                text: i18nextko.t(i18nPrefix + key.toLowerCase()),
                value: theEnum[key]
            }));

            return sorted ? options.sort((a, b) =>
                a.text().localeCompare(b.text()))
                : options;
    }
}
