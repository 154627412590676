import * as ko from 'knockout';
import {components, Observable, Subscribable} from 'knockout';
import ensureObservable from "../util/ensure-observable"
import ComponentInfo = components.ComponentInfo;
import Config = components.Config;
import {autobind} from "knockout-decorators";

interface AutocompleteInputViewModelParams {
    name: string | Subscribable<string>;
    terms: any | Subscribable<string[]>;
    options: Subscribable<string[]>;
    i18nPrefix: string | Subscribable<string>;
}

class AutocompleteInputViewModel {
    public name: Subscribable<string>;
    public terms: Subscribable<string[]>;
    public options: Subscribable<string[]>;
    public i18nPrefix: Subscribable<string>;
    public termQuery: Observable<string>;

    constructor(private params: AutocompleteInputViewModelParams) {
        this.name = ensureObservable(params.name);
        this.terms = params.terms;
        this.options = params.options;
        this.i18nPrefix = ensureObservable(params.i18nPrefix);
        this.termQuery= ko.observable("");
    }

    public labelKey = ko.pureComputed(() =>
        (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".label"
    );

    public placeholderKey = ko.pureComputed(() =>
        (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".placeholder"
    );

    public addKey = ko.pureComputed(() =>
        (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".add"
    );

    @autobind
    public onTermSelect(item) {
        // if a term is entered via copy/paste it is an array with one entry
        if(Array.isArray(item) && item.length > 0) {
            item = item[0];
        }

        const index = this.terms().findIndex(value => value == item);
        console.debug("onTermSelect", item, index);
        if(index === -1) {
            this.terms().push(item);
        }
        console.debug(this.terms());
    }

    @autobind
    public addTerm() {
        const term = this.termQuery().trim();
        if(term.length < 1) {
            return;
        }
        const index = this.terms().findIndex(value => value == term);
        if(index === -1) {
            this.terms().push(term);
        }
        this.termQuery("");
        console.debug(this.terms());
    }

    @autobind
    public removeTerm(term) {
        const index = this.terms().findIndex(value => value == term);
        if(index !== -1) {
            this.terms().splice(index, 1);
        }
    }
    
}

let component: Config = {
    viewModel: (params: AutocompleteInputViewModelParams, componentInfo?: ComponentInfo) => new AutocompleteInputViewModel(params),
    template: <string>require('./autocomplete-input.html')
}

export default component;

if (!ko.components.isRegistered('autocomplete-input')) {
    ko.components.register('autocomplete-input', component)
}
