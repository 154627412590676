import * as ko from 'knockout'
import {components, Observable, ObservableArray} from 'knockout'
import {SearchFilterItem} from "../../../pages/home/home";
import Config = components.Config;
import {autobind, computed} from "knockout-decorators";

interface ViewModelParams {
    titleKey: string
    searchFilterItems: SearchFilterItem[],
    selectedValues: ObservableArray;
}

class ViewModel {

    /**
     * The search result item.
     */
    items: SearchFilterItem[];

    /**
     * The title key of the filter box.
     */
    titleKey: string;

    /**
     * The selected values for this filter group.
     */
    selectedValues: ObservableArray;

    /**
     * All items visible?
     */
    expanded: Observable<boolean>;

    /**
     *
     */
    maxItemsSize = 4;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.items = params.searchFilterItems;
        this.titleKey = params.titleKey;
        this.selectedValues = params.selectedValues;
        this.expanded = ko.observable(false);
    }


    @computed
    get filterItems() {
        if(this.items && this.items.length > 0) {
            if(this.expanded() == false) {
                const maxItems = this.items.length < this.maxItemsSize ? this.items.length : this.maxItemsSize;
                return this.items.slice(0, maxItems);
            }
            return this.items;
        }
        return [];
    }

    @computed
    get isExpandable(): boolean {
        return this.items.length > this.maxItemsSize;
    }

    @autobind
    toggleExpanded() {
        this.expanded(!this.expanded());
    }

    getId(item: SearchFilterItem) {
        return 'cbx-' + item.term;
    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./search-filter-group.html')
};

export default component;

if (!ko.components.isRegistered('search-filter-group')) {
    ko.components.register('search-filter-group', component)
}
