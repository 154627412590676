import * as ko from 'knockout'
import {components, Observable} from 'knockout'
import {InstitutionDtoTypeEnum, SearchResultItemDto} from "../../../api/generated";
import * as L from "leaflet";
import {autobind, computed, observable} from "knockout-decorators";
import {displayName, markerIcon, round2Decimals, sortOpeningHours} from "../../util/common";
import Config = components.Config;
import i18nextko from "../../../bindings/i18nko";
import * as moment from 'moment';
import "../../../../images/marker-icon-doctor.png";
import "../../../../images/marker-icon-therapist.png";
import "../../../../images/marker-icon-home.png";

interface ViewModelParams {
    searchResultItem: SearchResultItem,
    map: L.Map
}

export class SearchResultItem  {

    public dto: SearchResultItemDto

    /**
     * Weight for sorting by relevance.
     */
    @observable
    weight: number;

    /**
     * Distance for sorting by distance.
     */
    @observable
    distance: number;

    /**
     * Map marker for this result item.
     * Initialize on first access.
     */
    mapMarker: L.Marker;

    constructor(dto: SearchResultItemDto) {
        this.dto = dto;
        this.weight = 0;
        this.distance = 0;
        this.mapMarker = null;
    }

    get person() {
        return this.dto.person;
    }

    get institution() {
        return this.dto.department.institution;
    }

    get department() {
        return this.dto.department;
    }

    /**
     * Get the marker and initialize if its not set so far.
     */
    get marker() {
        return this.mapMarker ? this.mapMarker : this.mapMarker = L.marker(
            new L.LatLng(this.institution.address.latitude, this.institution.address.longitude), {
                icon: markerIcon(this.person.type.toLowerCase())
            })
            .bindPopup(displayName(this.person) + "<br/>" + this.institution.name);
    }
}

class ViewModel {

    /**
     * The search result item.
     */
    item: SearchResultItem;

    public imageBasePath: string = config.profileImageEndpoint;

    public showDetails: Observable<boolean>;

    /**
     * Reference to map.
     */
    map: L.Map;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.item = params.searchResultItem;
        this.map = params.map;
        this.showDetails = ko.observable(false);
    }

    get person() {
        return this.item.dto.person;
    }

    get institution() {
        return this.item.dto.department.institution;
    }

    get department() {
        return this.item.dto.department;
    }

    get areasOfSubject() {
        return this.person && this.item.dto.areasOfSubject ? this.item.dto.areasOfSubject.join(", ") : "";
    }

    get keyAspects() {
        return this.person && this.item.dto.keyAspects ? this.item.dto.keyAspects.join(", ") : "";
    }

    get phone() {
        return this.item.dto.phone  ? this.item.dto.phone :
            this.department.phone ? this.department.phone : this.person.phone;
    }

    get fax() {
        return this.item.dto.fax  ? this.item.dto.fax : this.department.fax;
    }

    get showEmail() {
        return this.item.dto.hideEmail !== true;
    }

    get email() {
        return this.item.dto.email  ? this.item.dto.email :
            this.department.email ? this.department.email : this.person.email;
    }

    get websiteUrl() {
        const url = this.item.dto.website ? this.item.dto.website : this.department.website;
        return url && !url.startsWith("http") ? 'https://' + url : url;
    }
    get websiteName() {
        const url = this.websiteUrl;
        try {
            return url ? new URL(url).host : url;
        } catch (e) {
            return url;
        }
    }

    get isHospital() {
        return this.institution.type == InstitutionDtoTypeEnum.Hospital;
    }

    get isTherapy() {
        return this.institution.type == InstitutionDtoTypeEnum.Therapy;
    }

    get isTherapist() {
        return this.institution.type == InstitutionDtoTypeEnum.Therapist;
    }

    get isPrivate() {
        return this.institution.type == InstitutionDtoTypeEnum.Privat;
    }

    get displayName() {
        return displayName(this.person);
    }

    get institutionName() {
        return this.institution.name;
    }

    get address() {
        return this.institution.address;
    }

    get showStreet() {
        return this.institution.hideAddress !== true && this.institution.homeVisitsOnly !== true;
    }

    get street() {
        let street = this.address.street + ' ' + this.address.housenumber;
        if(this.address.staircase) {
            street += "/" + this.address.staircase;
        }
        if(this.address.doornumber) {
            street += "/" + this.address.doornumber;
        }
        return street;
    }

    get languages() {
        return this.person.languages ? this.person.languages.join(", ") : "";
    }

    get insurances() {
        return this.item.dto.insurances ? this.item.dto.insurances.map(
                insurance => i18nextko.t("personDepartment.insurance.shortName." + insurance.toLowerCase())())
                .join(", ")
            : "";
    }

    get medicate() {
        return this.item.dto.medicate ? i18nextko.t("personDepartment.medicate." + this.item.dto.medicate.toLowerCase())() : "";
    }

    get openingHours() {
        return this.item.dto.openingHours ? sortOpeningHours(this.item.dto.openingHours) : [];
    }

    get displayDistance() {
        return this.item.distance > 0 ? round2Decimals((this.item.distance / 1000)) : 0;
    }

    public get showDetailsAvailable() {
        return this.showDetails() === false
            && (this.insurances !== '' || this.medicate !== '');
    }

    public toggleDetails() {
        this.showDetails(!this.showDetails());
    }

    get profileImageStyles() {
        if(this.person.profileImage && this.person.profileImage.id) {
            return `background-image: url('${config.profileImageEndpoint}${this.person.profileImage.id}/${moment(this.person.profileImage.modified).valueOf()}'), url('/${config.publicPath}/images/person-dummy.png');`;
        }
        return `background-image: url('/${config.publicPath}/images/person-dummy.png');`;
    }

    @autobind
    public centerMarker() {
        if(this.isMapEnabled) {
            const markerBounds: L.LatLngBounds = L.latLngBounds([this.item.marker.getLatLng()]);
            this.map.fitBounds(markerBounds);
            this.item.marker.openPopup();
        }
    }

    @computed
    public get isMapEnabled():boolean {
        return this.map && this.map !== null;
    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./search-result-item.html')
};

export default component;

if (!ko.components.isRegistered('search-result-item')) {
    ko.components.register('search-result-item', component)
}
