/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import * as ko from "knockout";
import ensureObservable from "../util/ensure-observable"
import {components, Observable, Subscribable} from "knockout";
import Config = components.Config;
import ComponentInfo = components.ComponentInfo;



interface TextAreaViewModelParams {
  value: any | Subscribable;
  rows: number | Subscribable<number>;
  name: string | Subscribable<string>;
  cssClass: string | Subscribable<string>;
  cssPrefix: string | Subscribable<string>;
  i18nPrefix: string | Subscribable<string>;
  showLabel: boolean | Subscribable<boolean>;
}


class TextAreaViewModel
{
  value: Subscribable<string>;
  name: Subscribable<string>;
  cssClass: Subscribable<string>;
  cssPrefix: Subscribable<string>;
  i18nPrefix:  Subscribable<string>;
  rows: Subscribable<number>;
  showLabel: Subscribable<boolean>;
  
  constructor(private params:TextAreaViewModelParams) {
    this.value = ensureObservable(params.value);
    this.name = ensureObservable(params.name);
    this.cssClass = ensureObservable(params.cssClass || "");
    this.cssPrefix = ensureObservable(params.cssPrefix || "form");
    this.i18nPrefix = ensureObservable(params.i18nPrefix);
    this.rows = ensureObservable(params.rows || 8);
    this.showLabel = ensureObservable(params.showLabel == false ? false : true);
  }  
  
  public cssClassField = ko.pureComputed(
      () => "form-control " + this.cssPrefix() + "__textarea " + this.cssPrefix() + "__textarea--" + this.name() + " " + this.cssClass()
  );
  public cssClassLabel = ko.pureComputed(
      ()=> this.cssPrefix() + "__label " + this.cssPrefix() + "__label--" + this.name()
  );
  public cssClassValidation = ko.pureComputed(
      () => "validationMessage " + this.cssPrefix() + "__validationMessage " + this.cssPrefix() + "__validationMessage--" + this.name()
  );  
  public labelKey = ko.pureComputed(
    () => (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".label"
  );

  public placeholderKey  = ko.pureComputed( () =>
      (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".placeholder"
  );
}

let component: Config = {
  viewModel: (params:TextAreaViewModelParams, componentInfo?: ComponentInfo) => new TextAreaViewModel(params),
  template: <string>require('./text-area.html')
}

export default component;
