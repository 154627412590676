import * as ko from 'knockout';
import {components, Observable, ObservableArray, Subscription} from 'knockout';
import {ValidationGroup}  from 'knockout.validation';
import {
    DepartmentDto,
    InstitutionDto, OpeningHours,
    SearchResultItemDto,
    SearchResultItemDtoInsurancesEnum, SearchResultItemDtoMedicateEnum
} from "../../../api/generated";
import {autobind, computed, observable, unwrap} from "knockout-decorators";
import "../../widgets/autocomplete-input"
import {postbox} from "../../util/postbox";
import {dataApi} from "../../../api/api-wrapper";
import Config = components.Config;
import {App} from "../../../app";
import {isAdminView, sortOpeningHours} from "../../util/common";

interface ViewModelParams {
    item: SearchResultItemDto,
    institutions: ObservableArray<InstitutionDto>;
    departments: ObservableArray<DepartmentDto>;
    successCallback: (vm: PersonDepartmentEditViewModel) => void;
    cancelCallback: (vm: PersonDepartmentEditViewModel) => void;
}

export class PersonDepartmentEditViewModel {

    /**
     * The search result item.
     */
    @observable({deep: true, expose: true})
    item: SearchResultItemDto

    /**
     * The institution options
     */
    institutions: ObservableArray<InstitutionDto>;

    /**
     * The department options
     */
    departments: ObservableArray<DepartmentDto>;

    /**
     * The selected institution id.
     */
    selectedInstitutionId: Observable<string>;

    /**
     * The selected department id.
     */
    selectedDepartmentId: Observable<string>;

    /**
     * Subscription for institution change.
     */
    public institutionModifiedSubscription: Subscription;

    public successCallback: (vm: PersonDepartmentEditViewModel) => void;
    public cancelCallback: (vm: PersonDepartmentEditViewModel) => void;

    @observable({deep: true, expose: true})
    public openingHours: OpeningHours;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.item = params.item;
        sortOpeningHours(this.item.openingHours);
        this.institutions = params.institutions;
        this.departments = params.departments;
        this.openingHours = {day: '', hours: ''};
        this.selectedInstitutionId = ko.observable(this.item.department && this.item.department.institution ? this.item.department.institution.id : undefined)
            .extend({required: true});
        this.selectedDepartmentId = ko.observable(this.item.department ? this.item.department.id : undefined)
            .extend({required: true}).extend({ rateLimit: 500 });

        this.successCallback = params.successCallback || function() {};
        this.cancelCallback = params.cancelCallback || function() {};

        this.institutionModifiedSubscription = this.selectedInstitutionId.subscribe(selectedId => {
            console.debug("institutionModified", selectedId);

            // auto select department if there is only one assigned to the selected institution.
            if(selectedId) {
                const departments = this.departments().filter(department => department.institution.id === selectedId);
                if(departments.length === 1) {
                    console.debug("institution modified, auto select department", departments[0].id);
                    this.selectedDepartmentId(departments[0].id);
                }
            }
        });

        unwrap(this.item, "areasOfSubject").extend({required: true});
        unwrap(this.item, "keyAspects").extend({required: true});
    }

    @autobind
    public save() {
        const errors = ko.validation.group(this, { deep: true });
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        }

        console.log("save", this.item);

        if (this.item.id && this.item.id.trim().length > 0) {
            this.item.department = this.selectedDepartment;
            dataApi.putPersonDepartment(this.item.id, this.item)
                .then(response => {
                    // ensure every item has a medicate property to make it an observable
                    this.item = Object.assign({}, { medicate: undefined }, response.data);
                    this.successCallback(this);
                    return response.data;
                })
                .catch(error => {
                    postbox.addError('person.error.save');
                    return Promise.reject();
                });
        } else {
            this.item.department = this.selectedDepartment;
            dataApi.postPersonDepartment(this.item)
                .then(response => {
                    // ensure every item has a medicate property to make it an observable
                    this.item = Object.assign({}, { medicate: undefined }, response.data);
                    console.log(this.item);
                    this.successCallback(this);
                    return response.data;
                })
                .catch(error => {
                    postbox.addError('person.error.save');
                    return Promise.reject();
                });
        }
    }

    public cancel() {
        this.cancelCallback(this);
    }

    @computed
    get selectedInstitution() {
        console.debug("selInstitution", this.selectedInstitutionId());
        const selectedInstitutionId = this.selectedInstitutionId();
        return this.institutions().find(institution => institution.id == selectedInstitutionId);
    }

    @computed
    get selectedDepartment() {
        console.debug("selInstitution", this.selectedInstitutionId());
        const selectedDepartmentId = this.selectedDepartmentId();
        return this.departments().find(department => department.id === selectedDepartmentId);
    }

    @computed
    get departmentOptions(): DepartmentDto[] {
        console.debug("departmentOptions", this.selectedInstitutionId());
        if(this.selectedInstitutionId()) {
            return this.departments().filter(department => department.institution.id === this.selectedInstitutionId());
        }
        return [];
    }

    @autobind
    addOpeningHours() {
        if(this.openingHours.day.trim().length > 0) {
            this.openingHours.day = this.openingHours.day.trim();
            this.openingHours.hours = this.openingHours.hours.trim();
            this.item.openingHours.push(Object.assign({}, this.openingHours));
            sortOpeningHours(this.item.openingHours);
            this.openingHours.day = '';
            this.openingHours.hours = '';
        }
    }

    @autobind
    deleteOpeningHours(idx) {
        this.item.openingHours.splice(idx, 1);
    }

    public areOfSubjectOptions() {
        return ["Allgemeinmedizin", "Neurochirurgie", "Allgemeinchirurgie und Gefäßchirurgie", "Innere Medizin", "Anästhesiologie und Intensivmedizin", "Sonstige", "Frauenheilkunde und Geburtshilfe",
            "Kinder- und Jugendheilkunde", "Radiologie", "Unfallchirurgie", "Psychiatrie und psychotherapeutische Medizin",
            "Orthopädie und orthopädische Chirurgie", "Augenheilkunde und Optometrie", "Neurologie",
            "Haut- und Geschlechtskrankheiten", "Hals- Nasen- u. Ohrenheilkunde", "Urologie", "Orthopädie und Traumatologie",
            "Lungenkrankheiten", "Physikalische Medizin", "Neurologie und Psychiatrie", "Klinische Pathologie",
            "Medizinische u. Chemische Labordiagnostik", "Kinder- und Jugendpsychiatrie", "Plastische Chirurgie", "Innere Medizin"];
    }

    public keyAspectsOptions() {
        return ["Schmerz", "Schlaganfall (Vor-/Nachsorge)", "Multiple Sklerose",
            "Kopf und Gesichtsschmerzen", "Bandscheibenerkrankungen", "Bewegungstörungen", "Epilepsien"];
    }

    public insuranceOptions () {
        return App.enumOptions(SearchResultItemDtoInsurancesEnum, 'personDepartment.insurance.shortName.', false)
    }

    public medicateOptions () {
        return App.enumOptions(SearchResultItemDtoMedicateEnum, 'personDepartment.medicate.')
    }

    public dispose() {
        this.institutionModifiedSubscription.dispose();
    }

    public get isAdminView() {
        return isAdminView();
    }

}

const component: Config = {
    viewModel: (params: ViewModelParams) => new PersonDepartmentEditViewModel(params),
    template: <string>require('./person-department-edit.html')
};

export default component;

if (!ko.components.isRegistered('person-department-edit')) {
    ko.components.register('person-department-edit', component)
}
