import * as ko from "knockout";
import * as linkifyUrls from "linkify-urls";
import {Options} from "linkify-urls";
import * as $ from "jquery";

const defaults = {
    attributes: {
        'target': '_blank',
        'class': 'linkify'
    },
    'value': url => {
        const theURL = new URL(url);
        return theURL.hostname + theURL.pathname;
    }
};

/**
 * Knockout binding to linkify URLs in a string.
 * see https://github.com/sindresorhus/linkify-urls
 *
 * @author sia
 */
ko.bindingHandlers['linkify'] = {
    'init': function () {
        if (typeof linkifyUrls === "undefined") {
            throw new Error('linkify-urls package is required (https://www.npmjs.com/package/linkify-urls)');
        }
        // Prevent binding on the dynamically-injected HTML (as developers are unlikely to expect that, and it has security implications)
        return {'controlsDescendantBindings': true};
    },
    'update': function (element, valueAccessor, allBindingsAccessor) {
        const options: Options = $.extend(true, {}, defaults, allBindingsAccessor().linkifyOptions);
        let value = ko.utils.unwrapObservable(valueAccessor());
        // escape special characters but keep the ampersand
        if (value) {
            value = linkifyUrls(strip_tags(value, config.allowedTags), options);
        }
        // setHtml will unwrap the value if needed
        ko.utils.setHtml(element, value);
    }
};

function strip_tags(input, allowed) {
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi
    return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
    })
}
