import {
    AddressDto, DepartmentDto, InstitutionDto,
    InstitutionDtoTypeEnum, OpeningHours,
    PersonDto,
    PersonDtoGenderEnum,
    PersonDtoTypeEnum, ProfileImageDto, SearchResultItemDto,
    SearchResultItemDtoMedicateEnum
} from "../../api/generated";
import {App} from "../../app";
import globalState from "../../global-state";
import {Router} from "@profiscience/knockout-contrib-router";
import * as L from "leaflet";

export function displayName(person: PersonDto): string {
    return person.title + ' ' + person.firstName + ' ' + person.lastName;
}

export function round2Decimals(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

/**
 * Get the options for the institution type.
 */
export function institutionTypeOptions() {
    return App.enumOptions(InstitutionDtoTypeEnum, "institution.type.");
}

/**
 * Get the options for the person type.
 */
export function personTypeOptions() {
    return App.enumOptions(PersonDtoTypeEnum, "person.type.");
}

/**
 * Get the options for the person gender type.
 */
export function personGenderOptions() {
    return App.enumOptions(PersonDtoGenderEnum, "person.gender.");
}

/**
 * Get the options for the person medicate type.
 */
export function personMedicateOptions() {
    return App.enumOptions(SearchResultItemDtoMedicateEnum, "person.medicate.");
}

export function createInstitution(): InstitutionDto {
    return {
        name: "",
        type: undefined,
        address: createAddress(),
        departments: [],
        barrierFree: false,
        homeVisitsOnly: false,
        hideAddress: false,
    }
}

export function createAddress(): AddressDto {
    return {
        country: "Österreich",
        city: "",
        zip: null,
        street: "",
        housenumber: "",
        staircase: null,
        doornumber: "",
        longitude: null,
        latitude: null
    }
}

export function createPerson(): PersonDto {
    return {
        type: undefined,
        gender: undefined,
        title: '',
        titlePostgrade: '',
        titleConferred: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        certified: false,
        languages: ["Deutsch", "Englisch"],
        profileImage: null
    }
}

export function createProfileImage(): ProfileImageDto {
    return {
        filename: undefined,
        mimeType: undefined,
        data: undefined
    }
}

export function createDepartment(): DepartmentDto {
    return {
        name: '',
        phone: '',
        fax: '',
        email: '',
        website: '',
        institution: null
    }
}

export function createSearchResultItem(person: PersonDto):SearchResultItemDto {
    return {
        person: person,
        department: undefined,
        institution: undefined,
        areasOfSubject: [],
        keyAspects: [],
        insurances: [],
        medicate: undefined,
        phone: "",
        fax: "",
        email: "",
        website: "",
        openingHours: [],
        hideEmail: false,
        approved: false,
        published: false
    }
}

export function isAdminView() {
    return Router.getPathFromLocation().startsWith("/admin");
}

/**
 * Default data table options.
 */
export function dataTableOptions() {
    return {
        dom:"<'row'<'col-12 col-md-6'l><'col-12 col-md-6'f>>" +
            "<'row'<'col-12'tr>>" +
            "<'row'<'col-12 col-md-6'i><'col-12 col-md-6'p>>",
        deferRender: true,
        paging: true,
        autoWidth: false
    }
}

export function sortOpeningHours(hours: OpeningHours[]) {
    const sorter = {
        "mo": 1,
        "di": 2,
        "mi": 3,
        "do": 4,
        "fr": 5,
        "sa": 6,
        "so": 7,
        'unknown': 8,
    }

    if(!hours || hours.length < 2) {
        return hours;
    }

    return hours.sort((a, b) => {
        const aDay = a.day && a.day.trim().length >= 2 ? a.day.toLowerCase().slice(0, 2) : 'unknown';
        const bDay = b.day && b.day.trim().length >= 2 ? b.day.toLowerCase().slice(0, 2) : 'unknown';
        console.log(aDay, bDay);
        const aSort = sorter[aDay] ? sorter[aDay] : 8;
        const bSort = sorter[bDay] ? sorter[bDay] : 8;
        return aSort - bSort;
    });
}

export function markerIcon(iconVariant: string): L.Icon {
    return L.icon({ iconUrl: `/app/images/marker-icon-${iconVariant}.png`,
        iconSize:    [25, 41],
        iconAnchor:  [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize:  [41, 41]
    })
}


